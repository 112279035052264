.sso-applications-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 40px;
}
.sso-applications-loader div {
  position: absolute;
  top: 16px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #EA623A;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.sso-applications-loader div:nth-child(1) {
  left: 8px;
  animation: sso-applications-loader1 0.6s infinite;
}
.sso-applications-loader div:nth-child(2) {
  left: 8px;
  animation: sso-applications-loader2 0.6s infinite;
}
.sso-applications-loader div:nth-child(3) {
  left: 32px;
  animation: sso-applications-loader2 0.6s infinite;
}
.sso-applications-loader div:nth-child(4) {
  left: 56px;
  animation: sso-applications-loader3 0.6s infinite;
}
@keyframes sso-applications-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes sso-applications-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes sso-applications-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.card-link {
  margin: 5px !important;
  transition: .25s;
  min-height: 90px;
}
.card-link:hover {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
}
.card-link__logo {
  height: 30px;
}
.card-link__title {
  position: relative;
  font-size: 30px;
  font-weight: bold;
  line-height: 17px;
}
.card-link__title.sbx, .card-link__title.prp {
  margin-top: -10px;
}
.card-link__title.label:after {
  font-size: 14px;
  font-weight: 200;
  position: absolute;
  bottom: -18px;
  left: 0;
}
.card-link__title.sbx:after {
  content: 'Sandbox';
  color: #64ADD0;
}

.card-link__title.prp:after {
  content: 'Pre-Production';
  color: #FCC759;
}

.dropdown-menu-app {
  padding: 0 !important;
  border-radius: 8px;
  border: none;
}

.dropdown-menu-app .text-muted {
  color: #d6d5d5 !important;
}

.dropdown-menu-app .card-link {
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  min-height: 40px !important;
  height: 40px !important;
}

.dropdown-menu-app .card-link .card-body {
  padding: 0 15px;
}


.dropdown-menu-app .card-link .card-link__logo {
  height: 25px;
}

.dropdown-menu-app .card-link:hover {
  background-color: rgba(17, 17, 26, 0.05);
}

.dropdown-menu-app .card-link .card-body {
  align-self: start;
}

.dropdown-menu-app .card-link .card-body .card-link__title {
  font-size: 18px;
  font-weight: bold;
  line-height: 12px;
}

.dropdown-menu-app .card-link .card-body .card-link__title.label:after {
  font-size: 10px;
  font-weight: 200;
  position: absolute;
  bottom: -12px;
  left: 0;
}

.dropdown-menu-app .card-link .card-link__title.sbx, .dropdown-menu-app .card-link .card-link__title.prp {
  margin-top: -7px;
}

.sso-err-icon {
  margin-top: 2px;
}