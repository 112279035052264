@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://cdn.powerling.com/assets/custom/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://cdn.powerling.com/assets/custom/fonts/SourceSansPro-SemiBold.ttf');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://cdn.powerling.com/assets/custom/fonts/SourceSansPro-Bold.ttf');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://cdn.powerling.com/assets/custom/fonts/SourceSansPro-Black.ttf');
}