.bi {
  display: inline-block;
  vertical-align: -0.125em;
}

.dropdown-menu-app {
  width: 267px;
}

.btn-app-sized {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  line-height: 13px;
  padding: 0;
  margin: 0;
  margin-bottom: 5px;
}

.btn-app-sized img {
  width: 25px;
  margin-bottom: 5px;
}

.v-align-table-row-middle td {
  vertical-align: middle;
}

.badge {
  margin: 2px;
  padding: 5px 8px;
  font-weight: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}